// Variable overrides
$navbar-brand-width:                  200px;
$body-bg:                         #f5f5f5;

$breadcrumb-padding-y:              .5rem;
$breadcrumb-margin-bottom:          0rem;

$navbar-height:                       45px;

$navbar-brand-font-size:            1rem;

$breadcrumb-borders: (
);

$blue:       #31669C; 