// Here you can add other styles
.rowMargin .row {
    margin-bottom: .5rem;
}

.actionButtons .btn {
    margin-right: 1rem;
}

.react-tagsinput-tag {
    background-color: $primary;
    border-radius: 2px;
    border: 1px solid $primary;
    color: $white;
    display: inline-block;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px;
  }

  .tab-content { 
    border-radius: $border-radius;
  }

  .main .container-fluid {
    padding: 10px;
  }

  .table td {
      padding: .3rem;
  }

  .table th {
    padding: .5rem;
}


.padding-10 {
    padding: 10px
}

.atradnes-saraksts {
    min-width: 1000px;
}

.zoom-control {
    position: absolute;
    z-index: 1;
    top: .5em;
    left: .5em;
}

.ol-control {
    background-color: rgba(theme-color("secondary"), .5) !important;
    button{
        background-color: rgba(theme-color("primary"), .5) !important;
        height: 1.5em !important;
        width: 1.5em !important;
        font-size: 1.4em !important;
    }
    button:hover,
    button:focus {
        text-decoration: none;
        background-color: rgba(theme-color("primary"), .8) !important;
        color: white !important;
    }
  }

  .layer-switcher {
    position: absolute;
    z-index: 1;
    top: .5em;
    right: .5em;
    button{
        height: 1.7em !important;
        width: 2.7em !important;
    }
    ul {
        margin: 0;
        padding: 0;
        max-width: 500px;
      }
    li {
        display: flex;
        border-bottom: solid #999999 1px;
        padding: .5rem;
    }
    li:last-child {
    border-bottom: solid transparent 1px;
    }
    
    .layer {
    line-height: 1;
    }
    .layer .name {
    flex: 1;
    }
    .layer .name::first-letter {
    text-transform: capitalize;
    }
    .layer .checkbox {
    line-height: 1;
    margin-right: .5rem;
    }
    .layer span {
        padding-left: 5px;
    }
    .layer span i{
        cursor: pointer;
    }
}

.ol-attribution {
    button{
        height: 1.3em !important;
        width: 1.3em !important;
        font-size: 1.1em !important;
    }
}

